import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { useAuthStore } from '@/stores/authStore';
import 'remixicon/fonts/remixicon.css';
import { IonicVue } from '@ionic/vue';

import "./index.css";
/* Core CSS required for Ionic Components to work properly */
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { createPinia } from "pinia";


// Above the createApp() line
import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window).then(r => {});

import './registerServiceWorker';

const app = createApp(App)
  .use(IonicVue)
  .use(router);

app.use(createPinia());
// app.use(VueVirtualScroller);

router.isReady().then(() => {
  app.mount('#app');
});


// const { fetchUser,fetchSquads } = useAuthStore();
// fetchSquads()
// fetchUser()