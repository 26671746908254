import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "relative z-0 w-full mb-4" }
const _hoisted_3 = { class: "relative z-0 w-full mb-4" }
const _hoisted_4 = { class: "relative z-0 w-full mb-6 group" }
const _hoisted_5 = { class: "relative z-0 w-full mb-6 group" }
const _hoisted_6 = { class: "relative z-0 w-full mb-6 group" }
const _hoisted_7 = { key: 0 }

import {
  IonButton, IonIcon, IonLabel,
  IonItem, IonList, IonSelect, IonSelectOption, IonInput
} from '@ionic/vue';
import {search, refreshOutline} from 'ionicons/icons';
import {useRouter} from 'vue-router';
import {useMainStore} from '@/stores/mainStore'
import {useAuthStore} from '@/stores/authStore'

import {computed, nextTick} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentsSearchForm',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const mainStore = useMainStore()
const authStore = useAuthStore()
const emit = __emit
const documentSearchForm = computed(() => {
  return mainStore.documentSearchForm;
})
const router = useRouter();

function setSearchAndGetDocuments() {
  emit('close')
  mainStore.searchDocuments(documentSearchForm.value)
  nextTick(() => {
    mainStore.fetchDocuments()
    router.push(`/documents/all`)
  })
}

const documentSearchIsOn = computed(() => {
  return mainStore.documentSearchIsOn
})
const squads = computed(() => {
  return authStore.squads
})

function resetSearchAndGetDocuments() {
  emit('close')
  mainStore.searchDocuments({})
  nextTick(() => {
    mainStore.fetchDocuments()
    router.push(`/documents/all`)
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("label", {
        for: "search_name",
        class: "block mb-2 font-medium text-gray-900 dark:text-white"
      }, "Search Text", -1)),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((documentSearchForm.value.text) = $event)),
        type: "text",
        id: "search_name",
        class: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      }, null, 512), [
        [_vModelText, documentSearchForm.value.text]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(IonItem), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonLabel), { position: "stacked" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Search Text")
            ])),
            _: 1
          }),
          _createVNode(_unref(IonInput))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_unref(IonItem), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonSelect), {
            placeholder: "Choose a Template",
            interface: "action-sheet"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonSelectOption), { value: "apples" }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Apples")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonSelectOption), { value: "oranges" }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Oranges")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonSelectOption), { value: "bananas" }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Bananas")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "relative z-0 w-full mb-6 group" }, [
      _createElementVNode("label", {
        for: "status",
        class: "block mb-2 font-medium text-gray-900 dark:text-white"
      }, "Component Types"),
      _createElementVNode("select", {
        id: "status",
        class: "bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      }, [
        _createElementVNode("option"),
        _createElementVNode("option", null, "Motor")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_4, [
      _cache[10] || (_cache[10] = _createElementVNode("label", {
        for: "status",
        class: "block mb-2 font-medium text-gray-900 dark:text-white"
      }, "Status", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((documentSearchForm.value.status) = $event)),
        id: "status",
        class: "bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      }, _cache[9] || (_cache[9] = [
        _createElementVNode("option", null, null, -1),
        _createElementVNode("option", { value: "10" }, "Not Started", -1),
        _createElementVNode("option", { value: "20" }, "In Progress", -1),
        _createElementVNode("option", { value: "100" }, "Complete", -1)
      ]), 512), [
        [_vModelSelect, documentSearchForm.value.status]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[12] || (_cache[12] = _createElementVNode("label", {
        for: "owners",
        class: "block mb-2 font-medium text-gray-900 dark:text-white"
      }, "User", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((documentSearchForm.value.user) = $event)),
        id: "owners",
        class: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      }, _cache[11] || (_cache[11] = [
        _createElementVNode("option", null, null, -1),
        _createElementVNode("option", { value: "assignedToMe" }, "Assigned to Me", -1),
        _createElementVNode("option", { value: "createdByMe" }, "Created by Me", -1)
      ]), 512), [
        [_vModelSelect, documentSearchForm.value.user]
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[13] || (_cache[13] = _createElementVNode("label", {
        for: "status",
        class: "block mb-2 font-medium text-gray-900 dark:text-white"
      }, "Assigned Team", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((documentSearchForm.value.assignedTeam) = $event)),
        id: "status",
        class: "bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(squads.value, (squad) => {
          return (_openBlock(), _createElementBlock("option", {
            key: squad.id
          }, _toDisplayString(squad.name), 1))
        }), 128))
      ], 512), [
        [_vModelSelect, documentSearchForm.value.assignedTeam]
      ])
    ]),
    _createVNode(_unref(IonButton), {
      onClick: setSearchAndGetDocuments,
      class: "mb-4",
      expand: "block"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonIcon), {
          icon: _unref(search),
          class: "ion-margin-end"
        }, null, 8, ["icon"]),
        _cache[14] || (_cache[14] = _createTextVNode(" Search "))
      ]),
      _: 1
    }),
    (documentSearchIsOn.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_unref(IonButton), {
            onClick: resetSearchAndGetDocuments,
            fill: "outline",
            expand: "block"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonIcon), {
                icon: _unref(refreshOutline),
                color: "primary",
                class: "ion-margin-end"
              }, null, 8, ["icon"]),
              _cache[15] || (_cache[15] = _createTextVNode(" Reset "))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})