<template>
  <ion-page>

    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="goToMainMenu">
            <ion-icon slot="start" color="primary" :icon="chevronBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>   <ion-icon :icon="documentOutline"></ion-icon> Projects</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="p-2">
      <ion-list v-if="projects" class="ion-padding-top bg-white">
        <ion-item v-for="project in projects" :key="project.id"
                  @click="switchProject(project.id)" :class="{'bg-green-200': currentProject.id === project.id }" >
          <ion-label>
            <div class="flex justify-between mb-1">
              <div class="">
                <div class="truncate">{{ project.name }}</div>
                <div v-if="currentProject.id === project.id" class="text-sm text-gray-600">(current project)</div>
              </div>
              <div><ion-button fill="clear" >Switch To > </ion-button></div>
            </div>
          </ion-label>
        </ion-item>
      </ion-list>

    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonIcon,
  IonButtons,
    IonLabel,
    IonList,IonItem,
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
  IonCardHeader,
  onIonViewDidEnter,
} from '@ionic/vue';

import {chevronBackOutline, chevronForwardOutline, documentOutline} from 'ionicons/icons';
import {useRouter} from "vue-router";
import {useMainStore} from "@/stores/mainStore";
import {computed, ref} from "vue";
import APIHelper from "@/classes/APIHelper";

const router = useRouter();
const mainStore = useMainStore()
function goToMainMenu(){
  router.go(-1)
}

const projects = ref(null);
const currentProject = ref(null);
async function switchProject(id:number){
  APIHelper.API.get('/switch-project/' + id).then((resp: any) => {
    window.location.href = '/'
  }).catch((err: any) => {
    console.log(err)
  })
}

const loading = ref(false);
function fetchProjects() {
  loading.value = true;
  currentProject.value = null;
  projects.value = null;
  APIHelper.API.get('/projects').then((resp: any) => {
    projects.value = resp.data.projects
    currentProject.value = resp.data.currentProject
    loading.value = false;
  }).catch((err: any) => {
    console.log(err)
  })
}

onIonViewDidEnter(() => {
  fetchProjects();
})
</script>
