import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }

import {IonApp, IonRouterOutlet} from '@ionic/vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(IonApp), null, {
      default: _withCtx(() => [
        _createVNode(_unref(IonRouterOutlet))
      ]),
      _: 1
    })
  ]))
}
}

})