import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = {
  key: 1,
  class: ""
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "flex justify-between" }
const _hoisted_5 = {
  class: "",
  style: {"overflow-y":"scroll"}
}
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  class: "",
  style: {"width":"330px"}
}
const _hoisted_9 = { class: "font-bold mb-1 text-black truncate" }
const _hoisted_10 = { class: "mb-1" }
const _hoisted_11 = { class: "flex justify-between" }
const _hoisted_12 = { class: "grid grid-rows-4 gap-2 justify-stretch m-6 justify-around" }
const _hoisted_13 = { class: "border shadow p-2 rounded-lg text-gray-800 flex space-x-2" }
const _hoisted_14 = { class: "font-bold" }
const _hoisted_15 = { class: "border bg-gray-100 shadow p-2 rounded-lg text-gray-800 flex space-x-2" }
const _hoisted_16 = { class: "font-bold" }
const _hoisted_17 = { class: "border bg-orange-200 shadow p-2 rounded-lg text-orange-600 flex space-x-2" }
const _hoisted_18 = { class: "font-bold" }
const _hoisted_19 = { class: "border bg-green-200 shadow p-2 rounded-lg text-green-600 flex space-x-2" }
const _hoisted_20 = { class: "font-bold" }
const _hoisted_21 = { key: 3 }
const _hoisted_22 = { class: "flex justify-between" }
const _hoisted_23 = {
  class: "",
  style: {"overflow-y":"scroll","-ms-overflow-style":"none"}
}
const _hoisted_24 = { class: "flex" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { style: {"width":"330px"} }
const _hoisted_27 = { class: "font-bold mb-1 text-black truncate" }
const _hoisted_28 = { class: "mb-1" }
const _hoisted_29 = {
  key: 4,
  class: "pt-4 border-t"
}
const _hoisted_30 = { class: "flex justify-between mt-2" }
const _hoisted_31 = {
  class: "",
  style: {"overflow-y":"scroll"}
}
const _hoisted_32 = { class: "flex" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = {
  class: "",
  style: {"width":"330px"}
}
const _hoisted_35 = { class: "mb-1 text-black truncate" }
const _hoisted_36 = { class: "mb-1 text-gary-400 text-sm truncate" }

import {useRouter} from "vue-router";
import {playCircle, radio, library, search} from 'ionicons/icons';

  import {
  IonIcon,
  IonPage,
  IonHeader,
  IonButton,
  IonToolbar,
  IonFooter,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel
} from '@ionic/vue';
import {
  businessOutline,
  cubeOutline,
  documentOutline,
  flashOutline,
  shapesOutline,
  pricetagOutline,
  atCircleOutline,
  checkmarkCircleOutline
} from 'ionicons/icons';
import {ref, computed, nextTick, markRaw, onMounted} from "vue";
import {useCompStore} from '@/stores/compStore'
import {useAuthStore} from '@/stores/authStore'
import {useCableStore} from "@/stores/cablesStore";
import APIHelper from "@/classes/APIHelper";
import {useMainStore} from "@/stores/mainStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

const router = useRouter();
const authStore = useAuthStore()
const compStore = useCompStore()
const mainStore = useMainStore()
//Store
const user = computed(() => {
  return authStore.user
})

function gotoDocuments() {
  router.push({name: 'documents.all'})
}
function gotoTasks() {
  router.push({name: 'tasks.all'})
}
function gotoCables() {
  router.push({name: 'cables'})
}

function gotoComponents() {
  router.push({name: 'components'})
}

function gotoProjects() {
  router.push({name: 'projects'})
}

//Documents
const dashboardData = ref(null)

async function fetchDashboardData() {
  APIHelper.API.get('/dashboard').then((resp: any) => {
    console.log('fetchDashboardData', resp.data)
    dashboardData.value = resp.data
  }).catch((err: any) => {
    console.log(err)
  })

}

function goToDocument(document: any) {
  mainStore.setLoading(true)
  if (document.status === 'Complete') {
    nextTick(() => {
      router.push(`/document/${document.id}/view`)
    })
  } else {
    nextTick(() => {
      router.push(`/document/${document.id}/execute`)
    })
  }
}

function goToTask(task: any) {
  mainStore.setLoading(true)
  console.log('goToTask', task)
  router.push(`/task/${task.id}`)
}

onMounted(() => {
  fetchDashboardData();
  authStore.fetchUser();
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonHeader), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonToolbar), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTitle), null, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Home")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonContent), { class: "" }, {
        default: _withCtx(() => [
          (user.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_unref(IonCard), { class: "bg-white" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonCardHeader), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonCardTitle), null, {
                          default: _withCtx(() => [
                            _createTextVNode("Hi, " + _toDisplayString(user.value.name), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(IonCardSubtitle), { class: "lowercase" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(user.value.email), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (user.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_unref(IonCard), { class: "bg-white" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonCardHeader), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonCardTitle), null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(user.value.currentProject?.name), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(IonCardSubtitle), { class: "lowercase" }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("Project")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "font-bold text-2xl text-blue-400 ml-6 mb-4" }, " Inspections", -1)),
          (dashboardData.value && dashboardData.value.myDocs.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "font-medium ml-6" }, "My Inspections", -1)),
                  _createElementVNode("div", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (gotoDocuments())),
                    class: "pr-5 text-gray-500 text-sm"
                  }, "View All")
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dashboardData.value.myDocs, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "mr-4",
                        key: item.id,
                        onClick: ($event: any) => (goToDocument(item))
                      }, [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_unref(IonCard), { class: "border-blue-400 border" }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonCardContent), null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_9, _toDisplayString(item.name), 1),
                                  _createElementVNode("div", _hoisted_10, [
                                    _createElementVNode("span", {
                                      class: _normalizeClass([{
                        'bg-green-300 text-green-700': item.status === 'complete',
                          'bg-gray-300 text-gray-600': item.status === 'ready',
                            'bg-orange-300 text-white': item.status === 'started'
                      }, "text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"])
                                    }, _toDisplayString(item.status), 3)
                                  ])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ], 8, _hoisted_7))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_11, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "font-medium ml-6" }, "Inspection Stats", -1)),
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (gotoDocuments())),
              class: "pr-5 text-gray-500 text-sm"
            }, "View All")
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "font-medium" }, "Total:", -1)),
              _createElementVNode("div", _hoisted_14, _toDisplayString(dashboardData.value?.totalCount), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "font-medium" }, "Ready to started :", -1)),
              _createElementVNode("div", _hoisted_16, _toDisplayString(dashboardData.value?.todoCount), 1)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "font-medium" }, "In Progress:", -1)),
              _createElementVNode("div", _hoisted_18, _toDisplayString(dashboardData.value?.inProgressCount), 1)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "font-medium" }, "Complete:", -1)),
              _createElementVNode("div", _hoisted_20, _toDisplayString(dashboardData.value?.completedCount), 1)
            ])
          ]),
          (dashboardData.value && dashboardData.value.started.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "font-medium ml-6" }, "In Progress", -1)),
                  _createElementVNode("div", {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (gotoDocuments())),
                    class: "pr-5 text-gray-500 text-sm"
                  }, "View All")
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dashboardData.value.started, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "mr-4",
                        key: item.id,
                        onClick: ($event: any) => (goToDocument(item))
                      }, [
                        _createElementVNode("div", _hoisted_26, [
                          _createVNode(_unref(IonCard), { class: "border-blue-400 border" }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonCardContent), null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_27, _toDisplayString(item.name), 1),
                                  _createElementVNode("div", _hoisted_28, [
                                    _createElementVNode("span", {
                                      class: _normalizeClass([{
                        'bg-green-300 text-green-700': item.status === 'complete',
                          'bg-gray-300 text-gray-600': item.status === 'ready',
                            'bg-orange-300 text-white': item.status === 'started'
                      }, "text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"])
                                    }, _toDisplayString(item.status), 3)
                                  ])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ], 8, _hoisted_25))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (dashboardData.value && dashboardData.value.myTasks.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _cache[13] || (_cache[13] = _createElementVNode("div", { class: "font-bold text-2xl text-orange-400 ml-6 mb-4" }, " My Actions", -1)),
                  _createElementVNode("div", {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (gotoTasks())),
                    class: "pr-5 text-gray-500 text-sm"
                  }, "View All")
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dashboardData.value.myTasks, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "mr-4",
                        key: item.id,
                        onClick: ($event: any) => (goToTask(item))
                      }, [
                        _createElementVNode("div", _hoisted_34, [
                          _createVNode(_unref(IonCard), { class: "border" }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonCardContent), {
                                class: _normalizeClass({
                        'bg-green-100 ': item.status === 'complete',
                          'bg-gray-100 ': item.status === 'not started',
                            'bg-orange-100': item.status === 'started'
                      })
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_35, _toDisplayString(item.name), 1),
                                  _createElementVNode("div", _hoisted_36, _toDisplayString(item.description), 1)
                                ]),
                                _: 2
                              }, 1032, ["class"])
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ], 8, _hoisted_33))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})