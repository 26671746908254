<template>
  <ion-page>

    <ion-header>
      <ion-toolbar>
        <ion-title>Home</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="">

      <div v-if="user" class="">
        <ion-card class="bg-white">
          <ion-card-header>
            <ion-card-title>Hi, {{ user.name }}</ion-card-title>
            <ion-card-subtitle class="lowercase">{{ user.email }}</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </div>

      <div v-if="user" class="">
        <ion-card class="bg-white">
          <ion-card-header>
            <ion-card-title>{{ user.currentProject?.name }}</ion-card-title>
            <ion-card-subtitle class="lowercase">Project</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </div>

      <div class="font-bold text-2xl text-blue-400 ml-6 mb-4"> Inspections</div>
      <!--      MY INSPECTIONS -->
      <div v-if="dashboardData && dashboardData.myDocs.length > 0">
        <div class="flex justify-between">
          <div class="font-medium ml-6">My Inspections</div>
          <div  @click="gotoDocuments()" class="pr-5 text-gray-500 text-sm">View All</div>
        </div>
        <div class="" style="overflow-y: scroll">
          <div class="flex ">
            <div class=" mr-4" v-for="item in dashboardData.myDocs" :key="item.id" @click="goToDocument(item)">
              <div class="" style="width:330px;">
                <ion-card class="border-blue-400 border">
                  <ion-card-content>
                    <div class="font-bold mb-1 text-black truncate">{{ item.name }}</div>
                    <div class="mb-1">
                      <span :class="{
                        'bg-green-300 text-green-700': item.status === 'complete',
                          'bg-gray-300 text-gray-600': item.status === 'ready',
                            'bg-orange-300 text-white': item.status === 'started'
                      }"
                            class=" text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {{ item.status }}</span>
                    </div>
                  </ion-card-content>
                </ion-card>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="flex justify-between">
        <div class="font-medium ml-6">Inspection Stats</div>
        <div @click="gotoDocuments()" class="pr-5 text-gray-500 text-sm">View All</div>
      </div>

      <div class="grid grid-rows-4 gap-2 justify-stretch  m-6 justify-around">

        <div class="border  shadow p-2 rounded-lg text-gray-800 flex space-x-2  ">
          <div class="font-medium">Total:</div>
          <div class="font-bold ">{{ dashboardData?.totalCount }}</div>
        </div>


        <div class="border bg-gray-100 shadow p-2 rounded-lg text-gray-800 flex space-x-2">
          <div class="font-medium">Ready to started :</div>
          <div class="font-bold "> {{ dashboardData?.todoCount }}</div>
        </div>


        <div class="border bg-orange-200 shadow p-2 rounded-lg text-orange-600 flex space-x-2">
          <div class="font-medium">In Progress:</div>
          <div class="font-bold ">{{ dashboardData?.inProgressCount }}</div>
        </div>


        <div class="border bg-green-200 shadow p-2 rounded-lg text-green-600 flex space-x-2">
          <div class="font-medium">Complete:</div>
          <div class="font-bold ">{{ dashboardData?.completedCount }}</div>
        </div>
      </div>


      <div v-if="dashboardData && dashboardData.started.length > 0">
        <div class="flex justify-between">
          <div class="font-medium ml-6">In Progress</div>
          <div @click="gotoDocuments()" class="pr-5 text-gray-500 text-sm">View All</div>
        </div>
        <div class="" style="overflow-y: scroll; -ms-overflow-style: none;">
          <div class="flex ">
            <div class=" mr-4" v-for="item in dashboardData.started" :key="item.id" @click="goToDocument(item)">
              <div style="width:330px;">
                <ion-card class="border-blue-400 border">
                  <ion-card-content>
                    <div class="font-bold mb-1 text-black truncate">{{ item.name }}</div>
                    <div class="mb-1">
                      <span :class="{
                        'bg-green-300 text-green-700': item.status === 'complete',
                          'bg-gray-300 text-gray-600': item.status === 'ready',
                            'bg-orange-300 text-white': item.status === 'started'
                      }"
                            class=" text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {{ item.status }}</span>
                    </div>
                  </ion-card-content>
                </ion-card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--      MY TASKS -->
      <div v-if="dashboardData && dashboardData.myTasks.length > 0" class="pt-4 border-t ">
        <div class="flex justify-between mt-2">
          <div class="font-bold text-2xl text-orange-400 ml-6 mb-4"> My Actions</div>
          <div @click="gotoTasks()" class="pr-5 text-gray-500 text-sm">View All</div>
        </div>
        <div class="" style="overflow-y: scroll">
          <div class="flex ">
            <div class=" mr-4" v-for="item in dashboardData.myTasks" :key="item.id" @click="goToTask(item)">
              <div class="" style="width:330px">
                <ion-card class="border ">
                  <ion-card-content :class="{
                        'bg-green-100 ': item.status === 'complete',
                          'bg-gray-100 ': item.status === 'not started',
                            'bg-orange-100': item.status === 'started'
                      }" >
<!--                    <div class=" mb-1 text-black uppercase">{{ item.status }}</div>-->
                    <div class=" mb-1 text-black truncate">{{ item.name }}</div>
                    <div class=" mb-1 text-gary-400 text-sm  truncate">{{ item.description }}</div>
                  </ion-card-content>
                </ion-card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--      <div v-if="dashboardData && dashboardData.upNext.length > 0 ">-->
      <!--        <div class="flex justify-between">-->
      <!--          <div class="font-bold text-lg ml-3">Up Next</div>-->
      <!--          <div class="pr-5 text-sm">View All</div>-->
      <!--        </div>-->
      <!--        <div class="" style="overflow-y: scroll; -ms-overflow-style: none;">-->
      <!--          <div class="flex ">-->
      <!--            <div class=" mr-4" v-for="item in dashboardData.upNext" :key="item.id" @click="goToDocument(item)">-->
      <!--              <div style="width:330px;">-->
      <!--                <ion-card class="bg-white">-->
      <!--                  <ion-card-content style="height: 150px">-->
      <!--                    <div class="text-blue-600 text-sm mb-1">{{ item.template.name }}</div>-->
      <!--                    <div class="font-bold mb-1 text-black">{{ item.name }}</div>-->
      <!--                    <div class="mb-1">{{ item.description }}</div>-->
      <!--                    <div class="mb-1">-->
      <!--                      <span   :class="{-->
      <!--                        'bg-green-300 text-green-700': item.status === 'complete',-->
      <!--                          'bg-gray-200 text-gray-600': item.status === 'ready',-->
      <!--                            'bg-orange-300 text-white': item.status === 'started'-->
      <!--                      }"-->
      <!--                              class=" text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">-->
      <!--                        {{ item.status }}</span>-->
      <!--                    </div>-->
      <!--                  </ion-card-content>-->
      <!--                </ion-card>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {useRouter} from "vue-router";
import {playCircle, radio, library, search} from 'ionicons/icons';

  const router = useRouter();
import {
  IonIcon,
  IonPage,
  IonHeader,
  IonButton,
  IonToolbar,
  IonFooter,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel
} from '@ionic/vue';
import {
  businessOutline,
  cubeOutline,
  documentOutline,
  flashOutline,
  shapesOutline,
  pricetagOutline,
  atCircleOutline,
  checkmarkCircleOutline
} from 'ionicons/icons';
import {ref, computed, nextTick, markRaw, onMounted} from "vue";
import {useCompStore} from '@/stores/compStore'
import {useAuthStore} from '@/stores/authStore'
import {useCableStore} from "@/stores/cablesStore";
import APIHelper from "@/classes/APIHelper";
import {useMainStore} from "@/stores/mainStore";

const authStore = useAuthStore()
const compStore = useCompStore()
const mainStore = useMainStore()
//Store
const user = computed(() => {
  return authStore.user
})

function gotoDocuments() {
  router.push({name: 'documents.all'})
}
function gotoTasks() {
  router.push({name: 'tasks.all'})
}
function gotoCables() {
  router.push({name: 'cables'})
}

function gotoComponents() {
  router.push({name: 'components'})
}

function gotoProjects() {
  router.push({name: 'projects'})
}

//Documents
const dashboardData = ref(null)

async function fetchDashboardData() {
  APIHelper.API.get('/dashboard').then((resp: any) => {
    console.log('fetchDashboardData', resp.data)
    dashboardData.value = resp.data
  }).catch((err: any) => {
    console.log(err)
  })

}

function goToDocument(document: any) {
  mainStore.setLoading(true)
  if (document.status === 'Complete') {
    nextTick(() => {
      router.push(`/document/${document.id}/view`)
    })
  } else {
    nextTick(() => {
      router.push(`/document/${document.id}/execute`)
    })
  }
}

function goToTask(task: any) {
  mainStore.setLoading(true)
  console.log('goToTask', task)
  router.push(`/task/${task.id}`)
}

onMounted(() => {
  fetchDashboardData();
  authStore.fetchUser();
})
</script>

<style>
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */

</style>
