import API from './API';
const APIUrl = process.env.VUE_APP_API_URL_API;
class APIHelper {
  AppAPIs:any = [];
  API = new API(APIUrl);
  constructor() {
    this.AppAPIs.push(this.API);
    if (this.isUserLoggedIn()) {
      this.AppAPIs.forEach((itApi:any) => {
        itApi.setHeaderCommons(
          'Authorization',
          'Bearer ' + localStorage.getItem('tka')
        );
      });
    }
  }
  isUserLoggedIn() {
    return (
      localStorage.getItem('tka') !== null &&
      localStorage.getItem('tkr') !== null
    );
  }
}

export default new APIHelper();
