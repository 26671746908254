import APIHelper from '@/classes/APIHelper';
import {defineStore} from 'pinia'

export const useAuthStore = defineStore({
    id: 'authStore',
    state: () => ({
        user: {
            id: null,
            name: 'loading...'
        },
        squads: null,
        project:null,
        branch:null,
        company:null,
        team:null,
    }),
    getters: {},
    actions: {
        async fetchUser() {
            if (!this.user || !this.user.id) {
                try {
                    this.user = await APIHelper.API.get("/user").then((resp: any) => {
                        return resp.data.data
                    })
                } catch (error) {
                    return error
                }
            }
        },
        async fetchSquads() {
            if (!this.squads) {
                try {
                    this.squads = await APIHelper.API.get("/squads").then((resp: any) => {
                        return resp.data
                    })
                } catch (error) {
                    return error
                }
            }
        },
        async logout() {
            try {
                await APIHelper.API.post("/logout").then((resp: any) => {
                    return resp.data
                })
            } catch (error) {
                return error
            }
        }
    }
})

export default useAuthStore;
