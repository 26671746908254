import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonLabel,
} from '@ionic/vue';
import {
  fileTrayOutline,
  homeOutline,
  search,
    clipboard,
    pricetagOutline,
    appsOutline,
    checkmarkCircleOutline,
    clipboardSharp,
    clipboardOutline,
    flashOutline,
    cubeOutline,
} from 'ionicons/icons';

import {ref} from "vue";
import DocumentsSearchForm from "@/Components/document/DocumentsSearchForm.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'MainAppLayout',
  setup(__props) {

const isOpen = ref(false);
function setOpen(open: boolean) {
  isOpen.value = open;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), { id: "main-content" }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonTabs), { class: "" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet)),
          _createVNode(_unref(IonTabBar), {
            slot: "bottom",
            class: "pt-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTabButton), {
                tab: "home",
                href: "/home/dashboard"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(homeOutline) }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Home")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "documents",
                href: "/documents/all"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(clipboardOutline) }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Inspections")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "components",
                href: "/components/all"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(cubeOutline) }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Components")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "tasks",
                href: "/tasks/all"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(checkmarkCircleOutline) }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Actions")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "settings",
                href: "/settings/main"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(appsOutline) }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("More")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonModal), { "is-open": isOpen.value }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonHeader), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonToolbar), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonTitle), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(search),
                        color: "primary",
                        class: "ion-margin-end"
                      }, null, 8, ["icon"]),
                      _cache[7] || (_cache[7] = _createTextVNode(" Search "))
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonButtons), { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonButton), {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (setOpen(false)))
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode("Close")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(IonContent), null, {
            default: _withCtx(() => [
              _createVNode(DocumentsSearchForm, {
                onClose: _cache[1] || (_cache[1] = ($event: any) => (isOpen.value = false))
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["is-open"])
    ]),
    _: 1
  }))
}
}

})