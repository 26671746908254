import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between mb-1" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "truncate" }
const _hoisted_4 = {
  key: 0,
  class: "text-sm text-gray-600"
}

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonIcon,
  IonButtons,
    IonLabel,
    IonList,IonItem,
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
  IonCardHeader,
  onIonViewDidEnter,
} from '@ionic/vue';

import {chevronBackOutline, chevronForwardOutline, documentOutline} from 'ionicons/icons';
import {useRouter} from "vue-router";
import {useMainStore} from "@/stores/mainStore";
import {computed, ref} from "vue";
import APIHelper from "@/classes/APIHelper";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectsPage',
  setup(__props) {

const router = useRouter();
const mainStore = useMainStore()
function goToMainMenu(){
  router.go(-1)
}

const projects = ref(null);
const currentProject = ref(null);
async function switchProject(id:number){
  APIHelper.API.get('/switch-project/' + id).then((resp: any) => {
    window.location.href = '/'
  }).catch((err: any) => {
    console.log(err)
  })
}

const loading = ref(false);
function fetchProjects() {
  loading.value = true;
  currentProject.value = null;
  projects.value = null;
  APIHelper.API.get('/projects').then((resp: any) => {
    projects.value = resp.data.projects
    currentProject.value = resp.data.currentProject
    loading.value = false;
  }).catch((err: any) => {
    console.log(err)
  })
}

onIonViewDidEnter(() => {
  fetchProjects();
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonHeader), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonToolbar), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonButtons), { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonButton), { onClick: goToMainMenu }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        slot: "start",
                        color: "primary",
                        icon: _unref(chevronBackOutline)
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTitle), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(documentOutline) }, null, 8, ["icon"]),
                  _cache[0] || (_cache[0] = _createTextVNode(" Projects"))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonContent), { class: "p-2" }, {
        default: _withCtx(() => [
          (projects.value)
            ? (_openBlock(), _createBlock(_unref(IonList), {
                key: 0,
                class: "ion-padding-top bg-white"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projects.value, (project) => {
                    return (_openBlock(), _createBlock(_unref(IonItem), {
                      key: project.id,
                      onClick: ($event: any) => (switchProject(project.id)),
                      class: _normalizeClass({'bg-green-200': currentProject.value.id === project.id })
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonLabel), null, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _createElementVNode("div", _hoisted_2, [
                                _createElementVNode("div", _hoisted_3, _toDisplayString(project.name), 1),
                                (currentProject.value.id === project.id)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, "(current project)"))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("div", null, [
                                _createVNode(_unref(IonButton), { fill: "clear" }, {
                                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                                    _createTextVNode("Switch To > ")
                                  ])),
                                  _: 1
                                })
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "class"]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})